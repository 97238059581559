<section
  id="research"
  class="bg-black d-flex min-vh-100 justify-content-center align-items-center"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h1>Research</h1>
        <p>
          For nearly a decade, I have crafted and deployed algorithms to enable intelligent automated decision-making.
        </p>
        <div>
          <div class="text-center">
            <a
              target="_blank"
              href="https://scholar.google.com/citations?user=tyFULB4AAAAJ"
              class="btn btn-lg btn-outline-primary mx-2"
            >
              <i class="fab fa-google"></i>&nbsp;Scholar
            </a>
            <a
              target="_blank"
              href="https://research.kurzer.de"
              class="btn btn-lg btn-outline-primary mx-2"
            >
              <i class="far fa-file-alt"></i>&nbsp;Publications
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
